.box-header{
    width: 100%;
    background-color: #9cc3c2;
    border-radius: 3px;
}

.header-flex{
    display: none;
}

@media (min-width: 1100px){
    .header-flex{
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        margin: 30px auto 5px auto;
        padding: 5px 30px;
    }
}

