@media (min-width: 1200px){
    .drawer-menu{
        display: none;
    }
}

.drop-down-menu{
    display: none;
}

@media (min-width: 1200px){
    .drop-down-menu{
        display: flex;
    }
}

/*BOTTOM-HEADER*/
.border-bottom-header{
    background-color: #739392;
    padding: 2px 0;
}

.bottom-header {
    background-color: #c5d2d5;
}

.bottom-header-context{
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
}

@media (min-width: 500px){
    .bottom-header-context{
        height: 100px;
    }
}

/*BOTTOM-HEADER-LOGO*/
.bottom-header-left{
    display: flex;
    align-items: center;
}

.logo{
    height: 65px;
}

@media (min-width: 500px){
    .logo{
        height: 100px;
    }
}

.icon-size-home-header{
    margin-left: 10px;
    font-size: 35px;
    color: #739392;
}

/*BOTTOM-HEADER-OPTIONS*/
.bottom-header-right{
    display: flex;
    align-items: center;
}

.bottom-button-header{
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    height:auto;
    font-weight: 500;
}

@media (min-width: 1024px){
    .bottom-button-header {
        margin-left: 25px;
        margin-right: 25px;
    }
}

