.languages{
    display: flex;
    justify-content: space-between;
}

.box-language {
    display: flex;
}

.separator-language{
    width: 3px;
    height: 25px;
    background-color: #739392;
    margin: 11px 10px 0 10px;
}

@media (min-width: 768px){
    .separator-language{
        margin: 16px 10px 0 10px;
    }
}