.box-price-context{
    width: 90%;
    margin: auto;
}

.price-context{
    display: flex;
    justify-content: space-between;
    background-color: #c5d2d5;
    font-size: 18px;
}

.price-context:hover{
    transform: scale(1.05);
}

.price-context:not(:last-of-type) {
    margin-bottom: 15px;
}

.text-price{
    flex: 50%;
    padding: 15px;
    align-items: center;
}

@media (min-width: 768px){
    .text-price {
        flex: 55%;
    }
}

.price{
    flex: 50%;
    text-align: center;
    padding: 15px;
    background-color: #9cc3c2;
}

@media (min-width: 768px){
    .price {
        flex: 45%;
    }
}
