.teenagers-view{
    padding-top: 145px;
    min-height: 100vh;
}

.title-teenagers-view{
    margin-top: 30px;
}

.box-header-teenagers{
    width: 90%;
    margin: auto auto 2.5rem auto;
}

.box-header-teenagers img {
    width: 100%;
    object-fit: cover;
    height: 250px;
}

@media (min-width: 500px){
    .box-header-teenagers img {
        height: 400px;
    }
}

