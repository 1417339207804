.children-view{
    padding-top: 145px;
    min-height: 100vh;
}

.title-children-view{
    margin-top: 30px;
}

.box-header-children{
    width: 90%;
    margin: auto auto 2.5rem auto;
}

.box-header-children img {
    width: 100%;
    object-fit: cover;
    height: 250px;
}

@media (min-width: 500px){
    .box-header-children img {
        height: 400px;
    }
}