.title-aboutUs-view{
    margin-top: 30px;
}

.header-photo-aboutUs{
    margin: auto;
    width: 100%;
    text-align: center;
}

.header-photo-aboutUs img {
    margin: auto;
    width: 100%;
    max-width: 750px;
    height: 250px;
    object-fit: cover;
}

@media (min-width: 500px){
    .header-photo-aboutUs img {
        height: 400px;
    }
}

.box-help{
    display: block;
    justify-content: space-between;
    width: 80%;
    margin: auto;
}

@media (min-width: 768px){
    .box-help{
        display: flex;
    }
}

.context-help-text{
    flex: 0 0 calc(50% - 30px);
    margin-top: 20px;

}

.context-help-text p{
    font-size: 19px;
    line-height: 25px;
}
