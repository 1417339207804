.lines-drawer{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.lines-drawer div{
    width: 50px;
    height: 7px;
    background: #FFFFFF;
    border-radius: 4px / 4px;
}

.lines-drawer div:not(:last-of-type){
    margin-bottom: 5px;
}