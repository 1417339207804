.treatments{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: auto;
}

@media(min-width: 524px){
    .treatments{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(min-width: 768px){
    .treatments{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media(min-width: 1100px){
    .treatments{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media(min-width: 1400px){
    .treatments{
        grid-template-columns: repeat(5, 1fr);
    }
}


.scene{
    width: 200px;
    height: 210px;
    perspective: 600px;
    margin: 30px auto;
}

.card{
    position: relative;
    width: 100%;
    height: 100%;
    transition: all 1s ease;
    transform-style: preserve-3d;
}

.card:hover{
    transform: rotateY(180deg);
}

.card-face-front{
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-color: #c5d2d5;
    text-align: center;
    border-radius: 10px / 10px;
    overflow: hidden;
}

.card-image{
    width: 200px;
    height: 150px;
    object-fit: cover;
}


.box-text-frond{
    height: 40px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;
}

.card-text-frond{
    font-size: 16px;
    margin: 10px 15px 0 15px;
}

.card-face-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-color: #9cc3c2;
    text-align: center;
    border-radius: 10px / 10px;
    transform: rotateY(180deg);
}

.box-text-back{
    height: 200px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;
}

.card-text-back{
    margin: auto 15px;
}

