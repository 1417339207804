.box-link-list-text:not(:last-of-type){
    margin-right: 30px;
}

.text-header-photo{
    cursor: default;
    font-size: 18px;
    text-align: center;
}

.line-header-photo{
    height: 3px;
    width: 100%;
    margin: 5px 0;
}

.line-header-photo.active{
    background-color: #c5d2d5;
}