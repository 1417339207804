.footer{
    margin: 20px 0 0 0;
    padding: 35px;
    background-color: #9cc3c2;
}

.footer-context{
    display: block;
}

@media (min-width: 500px){
    .footer-context{
        display: flex;
        justify-content: space-between;
    }
}

.footer-flex-left{
    flex: 0 0 calc(40% - 15px);
}

@media (min-width: 768px){
    .footer-flex-left{
        flex: 0 0 calc(50% - 30px);
    }
}

@media (min-width: 480px){
    .footer-flex-left{
        flex: 0 0 calc(33% - 30px);
    }
}

.footer-flex-center{
    display: none;
}

@media (min-width: 768px){
    .footer-flex-center{
        display: unset;
        flex: 0 0 calc(33% - 15px);
    }
}

.footer-flex-right{
    flex: 0 0 calc(60% - 30px);
}

@media (min-width: 480px){
    .footer-flex-right{
        flex: 0 0 calc(50% - 30px);
    }
}

@media (min-width: 768px){
    .footer-flex-right{
        flex: 0 0 calc(33% - 30px);
    }
}

.context{
    padding-left: 15px;
}

.context p{
    font-size: 18px;
}

.icon-size-check{
    font-size: 19px;
    margin-right: 10px;
    color: green;

}

.footer-text{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.footer-text p{
    margin: 11px 0;
}

.box-context{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}

.box-context p{
    margin: 0 0 0 5px;
}

.box-context div{
    width: 32px;
}

.icon-transport{
    text-align: center;
}

.icon-size-streetView,
.icon-size-phone,
.icon-size-envelope{
    font-size: 28px;
    color: #739392;
}

.icon-size-bus{
    font-size: 28px;
    color: #E31B1E;
}

.icon-size-subway{
    font-size: 28px;
    color: #008479
}