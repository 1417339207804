/*CONTAINER*/
.container{
    width: 85%;
    margin: auto;
}

/*ICONS*/

.icon{

}

.icon-size{
    font-size: 25px;
}

/*BOTTOM-HEADER*/

/*TITLES*/

h3{
    font-size: 1.5rem;
    color: #638483;
    margin: 30px 0 5px 0;
    text-align: center;
}

@media (min-width: 500px){
    h3{
        font-size: 1.8rem;
    }
}


.line-title-h3{
    height: 5px;
    background-color: #638483;;
    margin-bottom: 25px;
}

h4{
    font-size: 1.45rem;
    color: #638483;
    margin: 0 0 5px 0;
    text-align: left;
}

@media (min-width: 500px){
    h4{
        font-size: 1.3rem;
    }
}

.line-title-h4{
    height: 2px;
    background-color: #638483;;
    margin-bottom: 15px;
    width: 180px;
}

/*BUTTON*/
.button{
    font-size: 16px;
    padding: 11px 16px;
    border-radius: 5px / 5px;
    border-style: none;
    outline: none;
    font-weight: 700;
}