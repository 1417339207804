/*HEADER*/

/*TOP-HEADER*/

.top-header{
    background-color: #9cc3c2;
}

.top-header-context{
    width: 95%;
    margin: auto;
    height: 70px;
    display: flex;
    justify-content: space-between;
}
@media (min-width: 500px) {
    .top-header-context {
        height: 45px;
    }
}

/*TOP-HEADER-LEFT*/
.top-header-left{
    display: block;
}

@media (min-width: 500px) {
    .top-header-left {
        display: flex;
        align-items: center;
    }
}

.box-context-header-left{
    display: flex;
    align-items: center;
}

.box-context-header-left:not(:last-of-type){
    margin-right: 15px;
}

.box-context-header-left p{
    margin: 8px 0 8px 15px;
    font-size: 15px;
}

.box-context-header-left a{
    margin: 8px 0 8px 15px;
    font-size: 18px;
    color: #000000;
    text-decoration: none;
}

@media (min-width: 768px){
    .box-context-header-left p{
        font-size: 18px;
    }
}

.icon-phone,
.icon-envelope{
    font-size: 20px;
    color: #739392;
}

.separator{
    display: none;
    width: 3px;
    height: 25px;
    background-color: #739392;
    margin-right: 15px;
}

@media (min-width: 500px) {
    .separator{
        display: flex;
        align-items: center;
    }
}

/*TOP-HEADER-RIGHT*/
.top-header-right{
    display: block;
}

@media (min-width: 500px) {
    .top-header-right{
        display: flex;
        align-items: center;
    }
}

.top-header-right p{
    margin-top: 8px;
}

@media (min-width: 500px) {
    .top-header-right p{
        margin: 15px 0;
    }
}

.separator-language{
    width: 3px;
    height: 13px;
    margin-top: 10px;
}

@media (min-width: 500px) {
    .separator-language {
        height: 25px;
        margin-top: 12px;

    }
}