/*GENERAL PRESENTATION*/

.presentation-container{
    padding-top: 145px;
    width: 90%;
    margin: auto;
}

.title-index-view{
    margin-top: 30px;
}

.line-title-view-h3{
    height: 5px;
    background-color: #638483;;
    margin-bottom: 25px;
}

.handsImg{
    height: 250px;
    background-repeat: no-repeat;
    background-image: url(https://images.unsplash.com/photo-1544027993-37dbfe43562a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80);
    background-size: cover;
    background-position: 50%;
}

@media (min-width: 800px), (min-height: 830px){
    .handImg{display: none}
    .line-title-view-h3{display: none}
    .title-index-view{display: none}
    .presentation-container {
        background-repeat: no-repeat;
        min-height: 590px;
        height: 70vh;
        background-image: url(https://i.ibb.co/FbY6BT7/mesa.jpg);
        background-position: 50% 20%;
        background-size: cover;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

@media (min-width: 1200px){
    .presentation-container {
        background-position: 50% 25%;
    }
}

.presentation-left{
    flex: 0 0 0;
}

@media (min-width: 1024px){
    .presentation-left{
        flex: 0 0 calc(50% - 30px)
    }
}

.presentation-right{
    margin: 20px auto auto auto;
}

@media (min-width: 800px){
    .presentation-right{
        flex: 0 0 calc(50% - 30px);
        margin: auto 50px auto auto;
    }
}