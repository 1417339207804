/*MAP*/
.maps-container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

@media (min-width: 1200px){
    .maps-container{
        display: flex;
        flex-direction: row;
    }
}

.map {
    width: 100%;
    height: 200px;
    margin: auto;
    z-index: 1;
}

@media (min-width: 500px) {
    .map {
        height: 350px;
    }
}

@media (min-width: 768px) {
    .map {
        height: 450px;
    }
}

.map-context{
    width: 100%;
    margin: auto;
}

@media (min-width: 1200px){
    .map-context{
        flex: 0 0 50%;
        margin: auto;
    }
}


/*INFO MAP*/

.box-map-info{
    display: block;
    margin: 20px 0;
}

@media (min-width: 630px){
    .box-map-info{
        display: flex;
        justify-content: space-around;
        margin: 20px 0;
    }
}

@media (min-width: 1200px){
    .box-map-info{
        display: block;
        flex: 0 0 40%;
        margin-left: 20px;
        padding: 20px 0 0 10px;
    }
}


.text-map-info{
    font-size: 18px;
    margin-left: 15px;
}

.box-transports,
.box-opening-time{
    padding-left: 10px;
}

.box-opening-time p{
    margin-left: 0;
}

.transport{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}

.transport p{
    margin: 0 0 0 5px;
}

.transport div{
    width: 32px;
}

.icon-transport{
    text-align: center;
}

.icon-size-bus{
    font-size: 28px;
    color: #E31B1E;
}

.icon-size-subway{
    font-size: 28px;
    color: #008479
}

.sub-box-map-info{
    padding-top: 15px;
}