.form-appointment{
    display: flex;
    flex-direction: column;
    width: 300px;
    min-height: 400px;
    margin: 5px auto 5px auto;
    padding: 10px;
    border-radius: 25px / 25px;
}

@media (min-width: 500px){
    .form-appointment{
        width: 400px;
        padding: 5px 15px 15px 15px;
    }
}

.form-appointment{
    background-color: #b1d2d1
}

@media (min-width: 800px), (min-height: 830px) {
    .form-appointment {
        background-color: rgba(255, 255, 255, .9);
    }
}

 .MuiDialogContent-root:first-child{
     padding-top: 0!important;
 }

.dialog-title{
    font-size: 1.3rem;
    text-align: center;
    margin: 5px auto 0 auto;
    width: 85%;
}




