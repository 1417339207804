.box-header-contact{
    display: block;
    margin: 40px auto 0 auto;
    min-height: 500px;
}

@media (min-width: 1200px){
    .box-header-contact-left{
        display: flex;
        justify-content: space-between;
    }
}

.box-header-contact-left{
    flex: 0 0 50%;
    margin-right: 30px;
}

.box-header-contact-left h4{
    font-size: 23px;
    margin-top: 25px;
}

.box-header-contact-left p{
    font-size: 20px;
}

.line-header-contact-explication{
    height: 3px;
    background-color: #638483;;
    margin-bottom: 30px;
    width: 100%;

}

.box-header-contact-right{
    flex: 0 0 50%;
}

.box-context-contact{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 25px 0 25px 5px;
}

.box-context-contact p{
    margin: 0 0 0 5px;
}

.icon-size-phone-contact,
.icon-size-envelope-contact{
    font-size: 28px;
    color: #739392;
}
