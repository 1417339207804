.box-title-explication-therapy{
    margin:auto;
    width: 80%;
}


.box-title-explication-therapy h4{
    text-align: center;
}

.box-explication-therapy{
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto 40px auto;
    background-color: #9cc3c2;
    padding: 30px;
    border-radius: 20px;
}


@media (min-width: 500px){
    .box-explication-therapy{
        width: 80%;
    }
}

@media (min-width: 1400px){
    .box-explication-therapy{
        height: 500px;
    }
}


.explication-therapy{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

@media (min-width: 1400px){
    .explication-therapy{
        flex-direction: row;
    }
}

.flex-left{
    flex: 0 0 calc(30% - 15px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
}

.box-img-explication-therapy{
    display:flex;
    justify-content: center;
}

.img-explication-therapy{
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 250px;
    transition: all 300ms ease-in-out;
}


@media (min-width: 1400px){
    .img-explication-therapy{
        height: 440px;
        margin: auto 0;
    }
}

.img-explication-therapy:hover{
    transform: scale(1.05);
}

.flex-center{
    display: none;
}

@media (min-width: 1400px){
    .flex-center{
        flex: 0 0 calc(25% - 15px);
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
}

.center-text{
    text-align: center;
    margin-top: 0;
}

.flex-right{
    flex: 0 0 calc(45% - 15px);
    display:flex;
    flex-direction: column;
    justify-content: center;
}

