.box-drawer-menu{
    height: 100%;
    padding: 25px;
    background: #c5d2d5;
}

.drawer-menu-list{
    padding-left: 15px;
}

.drawer-menu-link{
    text-decoration: none;
    color: #000000;
    cursor: default;
}

.drawer-menu-title-text{
    font-weight: 700;
    cursor: pointer;
}

.drawer-menu-title-text:hover{
    color: #FFFFFF
}

.drawer-menu-list-text{
    text-decoration: none;
    list-style: none;
    color: #000000;
    cursor: pointer;
}

.drawer-menu-list-text:hover{
    color: #FFFFFF;
}

.drawer-menu-list li{
    margin-bottom: 10px;
}