.box-right{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

@media (min-width: 1400px){
    .box-right{
        height: 440px;
    }
}

.box-title-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px 0;
}

@media (min-width: 1400px){
    .box-title-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
    }
}

.box-title-right div{
    margin: 3px auto 0 auto;
    height: 3px;
}

.box-title-right p{
    margin:0 auto;
    font-size: 18px;
}

.grid-right-text{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}

@media(min-width: 768px){
    .grid-right-text{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(min-width: 1400px){
    .grid-right-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.box-right-context{
    padding: 10px;
    border-radius: 20px;
    background-color: #c5d2d5;
}

@media(min-width: 1400px){
    .box-right-context{
        height: 370px;
    }
}


.box-right-text{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
}

.right-text{
    margin: 2px 0;
    font-size: 18px;
}

.icon-size-check-right{
    font-size: 16px;
    margin-right: 10px;
    color: green;
}