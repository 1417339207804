.faqs-prices{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width: 1200px){
    .faqs-prices{
        display: flex;
        flex-direction: row;
    }
}

.box-faqs-prices{
    flex:0 0 calc(50% - 50px);
}