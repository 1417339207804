.box-office{
    margin: auto;
    width: 100%;
}

.title-aboutUs-help{
    margin-top: 40px;
}

.box-office-img{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 50px auto 0 auto;
    row-gap: 25px;
    column-gap: 25px;
}

@media (min-width: 768px){
    .box-office-img{
        grid-template-columns: repeat(2, 1fr);
    }
}

.img-office{
    text-align: center;
    margin: auto;
    width: 80%;
}

.img-office img{

    width: 100%;
    max-width: 600px;
    object-fit: cover;
}

.box-text-office{
    width: 90%;
    margin: 30px auto;
}

.box-text-office{
    font-size: 19px;
    line-height: 25px;
}