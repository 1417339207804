.box-dialog-title{
    margin: 3px;
    background: #9cc3c2;
}

.box-dialog-content{
    padding: 15px;
    margin: 0;
    background-color: #c5d2d5;
}

.box-dialog-text{
    -webkit-overflow-scrolling: touch;
    max-width: 500px;
    max-height: 450px;
}

.box-dialog-actions{
    background: #9cc3c2;
}

.box-dialog-actions button{
    background-color: #8abab9;
}