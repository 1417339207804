.cursor-drop-down{
    cursor: default;
}

.bottom-header-text{
    cursor: default;
    font-family: 'Amiri', sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    transition: all .3s ease-in-out;
    text-align: center;
    line-height: 25px;
}

.bottom-header-text:hover{
    color: #FFFFFF;
}

.link-bottom-header-text{
    text-decoration: none;
    color: #000000;
    cursor: default;
}

.drop-down-list{
    position: absolute;
    left: -15px;
    top: 28px;
    background-color: rgba(197, 210, 213, .9);
    border-radius: 5px / 5px;
    z-index: 1;
    list-style: none;
    padding: 40px 0 5px 0;
    width: 250px;
}

.link-list-text{
    text-decoration: none;
    color: #000000;
    cursor: default;
}

.list-text {
    font-size: 1.1rem;
    padding: 11px 15px;
}

.list-text:hover {
    background-color: rgba(156, 195, 194, 0.9);
}

