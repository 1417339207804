.bottom-footer{
    display: flex;
    justify-content: space-between;
    background-color: #739392;
}

.bottom-footer p{
    margin-left: 30px;
}


.flex-bottom-text{
    display: flex;
    justify-content: flex-start;
    margin-right: 30px;
}

.advertisments{
    cursor: pointer;
}

