/*LANGUAGES*/

.language-text{
    font-size: 17px;
    cursor: default;
    margin-left: 1px;
    transition: all .3s ease-in-out;
}

@media(min-width: 768px){
    .language-text{
        font-size: 18px;
        margin-left: 4px;
    }
}

.language-text:hover{
    color: #FFFFFF;
}

.language-text.active{
    font-weight: 700;
}