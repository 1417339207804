.box-center{
    height: 440px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.box-title-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.box-title-center div{
    margin: 3px auto 0 auto;
    height: 3px;
}

.box-title-center p{
    margin:0;
    font-size: 18px;
}

.box-center-context{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
    background-color: #c5d2d5;
    height: 350px;
}

.box-center-text{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.center-text{
    margin: 2px 0;
    font-size: 18px;
}