.questions-context{
    margin: auto;
    width: 90%;
}

.question{
    display: flex;
    justify-content: space-between;
    background-color: #c5d2d5;
    padding: 15px;
    font-size: 18px;
}

.question.active{
    background-color: #9cc3c2;
}

.questions-context:not(:last-of-type){
    margin-bottom: 15px;
}

.response{
    background-color: #dce6e8;
    padding: 15px;
    font-size: 17px;
}

.icon-size-ChevronDown{
    font-size: 13px;
}

.icon-size-ChevronUp{
    font-size: 13px;
}